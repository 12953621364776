document.addEventListener('DOMContentLoaded', () => {
    iniciarFuncionalidades();
});

function iniciarFuncionalidades() {
    botonMenuDesplegable();
    mostrarPensamientoDisperso();
}

function botonMenuDesplegable() {
    const botonMenu = document.querySelector('#boton-menu-desplegable');
    const menuDesplegable = document.querySelector('#menu-desplegable')
    botonMenu.addEventListener('click', ()=> {
            menuDesplegable.classList.toggle('mostrar-menu');
        
    });
}

function mostrarPensamientoDisperso() {
    const pensamientosDispersos = document.querySelectorAll('.pensamiento-disperso');
    for (let i = 0; i < pensamientosDispersos.length; i++) {
        const pensamientoDisperso = pensamientosDispersos[i];
        pensamientoDisperso.addEventListener('click', ()=> {
            pensamientoDisperso.classList.toggle("ver-parrafos");
            
        });
    }
}
